import { fallbackURL } from '../consts/FallbackURL';
import { Flag } from '@mui/icons-material';

export const logout = () => {
    sessionStorage.clear();
    window.location.assign(fallbackURL);
};

export const formatNumber = (value) => {
    if (value === null || value === undefined) return;
    if (Number.isInteger(value)) {
        const result = new Intl.NumberFormat('es-ES').format(value) + ",00";
        if (result.length === 7) return `${result.substring(0, 1)}.${result.substring(1)}`
        return result
    }
    if (/\.\d$/.test(value.toString())) {
        const result = new Intl.NumberFormat('es-ES').format(value) + "0";
        if (result.length === 7) return `${result.substring(0, 1)}.${result.substring(1)}`
        return result
    }

    const result = new Intl.NumberFormat('es-ES').format(value);
    if (result.length === 7) return `${result.substring(0, 1)}.${result.substring(1)}`;
    return result
};

export const handleResponse = (response, message, functions, toArray) => {
    const { setSnackbarInfo, setInRequest, setIsOpen, setSelected, updateList } = functions

    if (response) {
        if (response.status.code === 1) {
            setSnackbarInfo({
                message,
                severity: 'success',
                open: true
            });
            setInRequest(false);
            setTimeout(() => {
                setSnackbarInfo({
                    message: '',
                    severity: 'success',
                    open: false
                });
                setIsOpen(false)
                toArray ? setSelected([]) : setSelected({ status: false, row: {} });
            }, 3000);
            updateList();
        }
        else {
            setSnackbarInfo({
                message: response.status.errmsg,
                severity: response.status.action === 'W' ? 'warning' : 'error',
                open: true
            });
            setInRequest(false);
            setTimeout(() => {
                setSnackbarInfo({
                    message: '',
                    severity: 'success',
                    open: false
                });
            }, 3000);
        }
    }
};

export const getGridProps = (columns, rows, row, setter, classes) => {
    return {
        columns,
        rows,
        onRowClick: (data) => validateRowSelected(data, row, setter),
        className: classes.root,
        autoPageSize: true,
        hideFooterSelectedRowCount: true,
        disableMultipleSelection: true,
        pagination: true,
        showCellRightBorder: false,
        disableColumnMenu: true,
        rowHeight: 36
    }
}

export const validateRowSelected = (newSelection, row, setSelected) => {
    if (row && row.id === newSelection.row.id) {
        newSelection.api.getRowElement(newSelection.id).classList.remove('Mui-selected')
        return setSelected({ status: false, row: {} });
    }
    const newSelected = { status: true, row: newSelection.row }
    newSelection.api.getRowElement(newSelection.id).classList.add('Mui-selected')
    return setSelected(newSelected)
};

export const transformDate = (str) => `${str.substring(8)}/${str.substring(5, 7)}/${str.substring(0, 4)}`

export const generateColumns = (titles, route) => {
    return titles.reduce((acc, title) => {
        title === 'Saldo anterior' || /\d{4}-\d{2}-\d{2}/.test(title)
        return [...acc,
        {
            field: title,
            headerName: title,
            sortable: /Fecha/.test(title) || route === '/pendientes',
            headerAlign: /Monto|Saldo|Ingresos|Egresos|Débito|Crédito|Deducciones|Impuestos/.test(title) ? 'right' : 'left',
            headerClassName: /ing|egr/.test(title) ? 'not-showing-header' : '',
            renderCell: (params) => {
                const style = {
                    width: '100%',
                    textAlign: /Monto|Saldo|Ingresos|Egresos|Débito|Crédito|Deducciones|Impuestos/.test(title) ? 'right' : 'left'
                }

                if (route === '/cobros/cobros' && title === 'Estado') {
                    const color = params.value === 'Cancelado' ? 'red' : params.value === 'Pendiente' ? '#a7a7a7' : params.value === 'Rechazado' ? 'orange' : 'green';
                    return <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                        <Flag htmlColor={color} />
                        <p style={{ marginLeft: '8px' }}>{params.value}</p>
                    </div>
                }
                if (route === '/saldos' && /Saldo/.test(title) && parseInt(params.value) < 0) return <p style={{ ...style, color: '#F00' }}>${params.value}</p>
                if (title === 'Monto Neto') return <p style={{ ...style, fontWeight: 'bold' }}>${params.value}</p>
                if (/Monto|Saldo|Ingresos|Egresos|Débito|Crédito|Deducciones|Impuestos/.test(title)) return <p style={style}>{params.value !== '' ? '$' : ''}{params.value}</p>
                return <p style={style}>{params.value}</p>
            },
            valueGetter: (params) => {
                if (params.value === null || params.value === undefined) return '';
                if ((title === 'Fecha' && (route === '/ventas' || route === '/pendientes')) || title === 'Transacción' || title === 'N° de seguimiento') return params.value;
                if (/Fecha|Autorización|Acreditación|Operación|Presentación/.test(title)) return transformDate(params.value);
                if (/^\d|^-\d(.*\d)?$/.test(params.value) && !/Hora|Cuotas|factura/.test(title)) return formatNumber(params.value);
                return params.value;
            },
            flex: /Fecha|Hora|ing|egr|Método de pago|Tipo de movimiento/.test(title) ? 0 : 1,
            width: (/Fecha/.test(title) && (route === '/ventas' || route === '/pendientes')) ? 200 : /Método de pago|Tipo de movimiento/.test(title) ? 250 : /Fecha|Hora|Cuotas/.test(title) ? 110 : /ing|egr/.test(title) ? 60 : 0
        }]
    }, [])
};


export const retrieveData = async (credentials, setRows, getData, setData) => {
    if (setRows) setRows([]);
    const data = await getData(credentials)
    setData(data)
};
