import React from 'react';
import styles from "./LandingPage.module.scss"
import { Grid } from '@mui/material';
import wave1 from "../../assets/img/bg-waves-landing-1.svg"
import wave2 from "../../assets/img/bg-waves-landing-2.svg"
import imgLanding from "../../assets/img/img-landing.svg"
import ButtonGradient from '../../components/utils/button/ButtonGradient';
import { useNavigate } from 'react-router-dom';

export default function LandingPage() {
    const navigate = useNavigate()

    const btnClick = () => {
        navigate("/login")
    }
    return (
        <section className={styles.page}>
            <Grid container spacing={2}>
                <Grid item xs={6} className={styles.containerColumn}>
                    <div className={styles.containerText}>
                        <h1 className={styles.title}>Estamos del lado de tu Comercio</h1>
                        <p className={styles.text}>Configurá gratis el alta de comercio y cobrá con Hooli y rebibí tu plata en el acto de manera facil, rapidá y segura.</p>
                        <ButtonGradient text="Comenzar" size={"large"} action={btnClick}/>
                    </div>
                </Grid>
                <Grid item xs={6} className={styles.containerColumn}>
                    <img src={imgLanding} alt="Dibujo de personas viendo su celular" className={styles.img}/>
                </Grid>
            </Grid>
            <img src={wave1} alt='misc' className={styles.wave}/>
            <img src={wave2} alt='misc' className={styles.wave}/>
        </section>
    )
};