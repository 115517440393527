export const Routes = {
    landing: {
        route: "/"
    },
    login: {
        route: "/login",
        wordKey: "login"
    },
    necesitamosDatos: {
        route: "/necesitamos-datos",
        wordKey: "Necesitamos datos"
    },
    altaComercios: {
        route: "/alta-comercios",
        wordKey: "Alta comercios",
        showInMenu: false,
    },
    comercioNoDisponible: { 
        route: "/no-disponibles",
        wordKey: "",
        showInMenu: false,
    },
    listadoComercios: { 
        route:"/listado-comercios",
        wordKey:"",
        showInMenu: false,
    },
    ingresoCuit: {
        route: "ingreso-cuit",
        wordKey: "Ingreso CUIT",
        showInMenu: false,
    },
    dashboard: {
        route: "/dashboard",
        wordKey: "Dashboard",
        showInMenu: true,
    },
    saldos: {
        route: "/saldos",
        wordKey: "Saldos",
        showInMenu: true,
    },
    ventas: {
        route: '/ventas',
        wordKey: "Ventas",
        showInMenu: true,
    },
    aCobrar: {
        route: '/aCobrar',
        wordKey: "A Cobrar",
        showInMenu: true,
    },
    resumen: {
        route: '/resumen',
        wordKey: "Resumen",
        showInMenu: true,
    },
    pagos: {
        route: '/cobros',
        wordKey: "Cobros",
        showInMenu: true,
        paso1: {
            route: "/cobros/paso1",
            wordKey: "Cobros > Datos > Paso 1"
        },
        paso2: {
            route: "/cobros/paso2",
            wordKey: "Cobros > Datos > Paso 2"
        },
        cobros: {
            route: '/cobros/cobros',
            wordKey: "Cobros > Ver cobros"
        }
    },
};