import React,{useEffect, useState} from 'react';
import { ContainerContacto } from './contacto';
import { Layaout } from '../../components/layaout';
import { ContainerInformacionFiscal } from './informacionFiscal';
import { ContainerIdentificacion } from './identificacion';
import { ContainerLocalizacion } from './localizacion';
import { ContainerDocumentos } from './containerDocumentos';
import { ProvinciaGet } from '../../services/hooli-services/Provincias/ProvinciasGet';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { comercioData, loading, provincias, userCredentials } from '../../recoilState/GlobalState';
import Loading from './loading/Loading';
import FinishPage from './finishPage';
import ModalAltaComercio from '../../components/alta-comercios/modal';
import { useNavigate } from 'react-router-dom';
export default function AltaComercios() {
  const [step, setStep] = useState(0);
  const [stepInfoFiscal, setStepInfoFiscal] = useState(1)
  const [stepDocumentos, setStepDocumentos] = useState({
   step: 1, 
   title: "Constancia de Inscripción de AFIP",
   subtitle: "Podés descargar tu constancia de inscripcion desde el sitio web de la AFIP"
  })
  const credentials = useRecoilValue(userCredentials);
  const localidades  = JSON.parse(sessionStorage.getItem("localidades"));
  const [comercio, setComercio] = useRecoilState(comercioData);
  const [load] = useRecoilState(loading);
  const setProvincias = useSetRecoilState(provincias);
  const navigate = useNavigate();
    const titlesLayout = [ 
      {title: "Identificación", subtitle: "Completá los siguientes datos de identificación de tu comercio."},
      {title:"Contacto", subtitle:"Completá los siguientes datos de Contacto de tu comercio."},
      {title:`Informacion Fiscal ${stepInfoFiscal}/3` , subtitle:"Completá los siguientes datos de Información Fiscal de tu comercio."},
      {title: stepDocumentos.title, subtitle: stepDocumentos.subtitle},
      {title: "Localización"},
     
    ]

    const getProvinicias = async ()=>{
      try{
        const response = await ProvinciaGet(credentials);
        if(response.status.code === 1 && response.result.length > 0) {
          setProvincias(response.result);
        }
        
      }catch(e){
        console.log("Error provinicas get",e)
      }
    }

    useEffect(()=> { 
      getProvinicias()
    },[])

    return (
      <>
      {load &&<Loading/> }
      <Layaout title={titlesLayout[step]?.title} subtitle={titlesLayout[step]?.subtitle} isNeedStepper={step !== 5} numberStep={step}> 
        {
          step === 0 && <ContainerIdentificacion nextStep={setStep} />
        }
        {
          step === 1 && <ContainerContacto nextStep={setStep}/>
        } 
        {
          step === 2 && <ContainerInformacionFiscal nextStep={setStep} stepInfoFiscal={stepInfoFiscal} setStepInfoFiscal={setStepInfoFiscal} />
        }
        {
          step === 3 && <ContainerDocumentos nextStep={setStep} setStepDocumentos={setStepDocumentos} stepDocumentos={stepDocumentos} />
        }
        {
          step === 4 && <ContainerLocalizacion nextStep={setStep} localidades={localidades} />
        }
        {
          step === 5 && <FinishPage/>
        }
      </Layaout>
    </>
    )
};